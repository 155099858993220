import { Box, Flex, Text } from '@chakra-ui/react';
import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import MainButton from 'components/button';
import { HSeparator } from 'components/separator/Separator';
import { useMemo } from 'react';
import {
  ItypeFee,
  PaymentIntermediaries,
  PaymentMethodDetail,
} from 'service/OrganizationAdmin/Organization';
import {
  MAX_PERCENT_TRANSACTION,
  MIN_PERCENT_TRANSACTION,
  REGEX_NUMBER,
  REGEX_NUMBER_COMMA,
} from 'util/Constant';
import ChargeConditions from './ChargeConditions';
import { FromValueType } from './ItemPannes';
interface IProps {
  onFinish: (values: any) => void;
  initialValue: FromValueType;
  isDisableForm: boolean;
  setIsDisableForm: (value: boolean) => void;
  signValue: string;
  setSignValue: (value: string) => void;
  enableEffectiveDate: boolean;
  setEnableEffectiveDate: (value: boolean) => void;
  paymentMethod: PaymentMethodDetail;
  paymentIntermediaries: PaymentIntermediaries;
  typeFee: ItypeFee[];
}

const FormItemPannes = ({
  onFinish,
  initialValue,
  isDisableForm,
  setIsDisableForm,
  signValue,
  setSignValue,
  enableEffectiveDate,
  setEnableEffectiveDate,
  paymentMethod,
  paymentIntermediaries,
  typeFee,
}: IProps) => {
  const [form] = useForm();
  const ID = useMemo(() => Date.now() + Math.floor(Math.random() * 100), []);

  const handleCancelEdit = () => {
    form.resetFields();
    setIsDisableForm(true);
    setSignValue(initialValue.sign);
  };

  return (
    <>
      <Form
        name={`FormItemPannes_${ID}`}
        onFinish={() => {
          if (paymentIntermediaries.partners.length <= 0) {
            return form.setFields([
              {
                name: 'paymentIntermediaries',
                errors: ['Vui lòng thiết lập TGTT tại tab TGTT'],
              },
            ]);
          } else onFinish(form.getFieldsValue());
        }}
        autoComplete="off"
        labelCol={{ span: 8 }}
        form={form}
        initialValues={initialValue}
        disabled={isDisableForm}
      >
        <Box w="80%" maxW="755px">
          <Form.Item
            name="feeName"
            label="Tên cấu hình"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên cấu hình!',
              },
            ]}
          >
            <Input className="custom-input" />
          </Form.Item>
          <Form.Item
            name="fixedCharge"
            label="Phí cố định (VND)"
            labelAlign="left"
            rules={[
              { pattern: REGEX_NUMBER, message: 'Vui lòng chỉ nhập số!' },
              { required: true, message: 'Vui lòng nhập phí cố định (VND)!' },
            ]}
          >
            <Input className="custom-input " placeholder="Nhập phí ..." />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            label="Phần trăm giá trị giao dịch (%)"
            name="feePercent"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập phần trăm giá trị giao dịch (%)!',
              },
              {
                validator: (_, value) => {
                  const convertValue = value
                    ? value.toString().split(',').join('.')
                    : '';
                  if (
                    convertValue &&
                    Number(convertValue) >= 0 &&
                    (Number(convertValue) < MIN_PERCENT_TRANSACTION ||
                      Number(convertValue) > MAX_PERCENT_TRANSACTION)
                  ) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
                message:
                  'Vui lòng nhập phần trăm giá trị giao dịch trong khoảng 0 đến 100!',
              },
              { pattern: REGEX_NUMBER_COMMA, message: 'Vui lòng chỉ nhập số!' },
            ]}
          >
            <Input className="custom-input " placeholder="Nhập phần trăm ..." />
          </Form.Item>

          <HSeparator my="4" />

          <Text color="#004E7B" fontSize="15px" fontWeight="600">
            Điều kiện tính phí
          </Text>
          <ChargeConditions
            form={form}
            isDisableForm={isDisableForm}
            signValue={signValue}
            setSignValue={setSignValue}
            enableEffectiveDate={enableEffectiveDate}
            setEnableEffectiveDate={setEnableEffectiveDate}
            paymentMethod={paymentMethod}
            paymentIntermediaries={paymentIntermediaries}
            typeFee={typeFee}
          />
          {!isDisableForm && (
            <Flex
              flexDirection="row"
              alignContent="center"
              justifyContent="end"
            >
              <>
                <Button
                  className="custom-button"
                  style={{
                    width: '95px',
                    background: '#A7A7A7',
                  }}
                  onClick={handleCancelEdit}
                >
                  Hủy
                </Button>
                <MainButton
                  style={{
                    width: '95px',
                    marginLeft: '10px',
                  }}
                  htmlType="submit"
                  text="Lưu"
                />
              </>
            </Flex>
          )}
        </Box>
      </Form>
      <Box w="80%" maxW="755px">
        <Flex flexDirection={'row'} alignContent="center" justifyContent="end">
          {isDisableForm && (
            <MainButton
              style={{
                width: '95px',
                background: '#F3C262',
                borderColor: '#F3C262',
              }}
              classButton="borderColor-F3C26"
              text={!initialValue._id ? 'Thiết lập' : 'Cập nhật'}
              onClick={() => setIsDisableForm(false)}
            />
          )}
        </Flex>
      </Box>
    </>
  );
};

export default FormItemPannes;
