import { message } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  ChangeFeeConfig,
  FeeConfig,
  ItypeFee,
  PaymentIntermediaries,
  PaymentMethodDetail,
} from 'service/OrganizationAdmin/Organization';
import OrganizationAdminService from 'service/OrganizationAdmin/OrganizationAdminService';
import { RootState } from 'store';
import { Type_Initstate } from 'store/appSlice';
import { validResponse } from 'util/API';
import { handleThrowError } from 'util/general';
import { valueCompareOperator } from './ChargeConditions';
import FormItemPannes from './FormItemPannes';
import * as Sentry from '@sentry/browser';

export interface FromValueType {
  feeName: string | null;
  fixedCharge: number | null;
  feePercent: number;
  paymentMethod: string;
  paymentIntermediaries: string;
  amount: number | null;
  sign: string;
  effectiveDate: Dayjs[] | number[];
  groupStudent: string;
  typeFee: string;
  _id?: string | null | undefined;
  status: number;
}

interface IProps {
  detailConfig?: FeeConfig;
  setRefreshListConfig: (value: boolean) => void;
  feeName?: string | null;
  paymentMethod: PaymentMethodDetail;
  paymentIntermediaries: PaymentIntermediaries;
  typeFee: ItypeFee[];
}

const ItemPannes = (props: IProps) => {
  const {
    detailConfig,
    setRefreshListConfig,
    feeName,
    paymentMethod,
    paymentIntermediaries,
    typeFee,
  } = props;
  const { FeeConfig, UpdateFeeConfig } = OrganizationAdminService;

  const initialValue = {
    feeName: detailConfig ? detailConfig.feeName : feeName,
    fixedCharge: detailConfig ? detailConfig.fixedCharge : null,
    feePercent: detailConfig ? Number(detailConfig.feePercent) : 0,
    groupStudent: 'all', // fixed all
    typeFee: detailConfig ? detailConfig.typeFee : '', // fixed all
    paymentMethod: detailConfig ? detailConfig.paymentMethod : '',
    paymentIntermediaries: detailConfig
      ? detailConfig.paymentIntermediaries
      : '',
    amount: detailConfig ? detailConfig.amount : null,
    sign: detailConfig ? detailConfig.sign : valueCompareOperator.eq,
    effectiveDate:
      detailConfig &&
      detailConfig.effectiveFromDate &&
      detailConfig.effectiveToDate
        ? [
            dayjs(detailConfig.effectiveFromDate),
            dayjs(detailConfig.effectiveToDate),
          ]
        : [0],
    _id: detailConfig ? detailConfig._id : '',
    status: detailConfig?.status ?? detailConfig?.status,
  };

  const [isDisableForm, setIsDisableForm] = useState(true);
  const [signValue, setSignValue] = useState(initialValue.sign);
  const [enableEffectiveDate, setEnableEffectiveDate] = useState(
    initialValue.effectiveDate.length === 2,
  );

  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { schoolDetail } = appState;

  const orgCode = useMemo(
    () => (schoolDetail ? schoolDetail.orgCode : ''),
    [schoolDetail],
  );
  const schoolCode = useMemo(
    () => (schoolDetail ? schoolDetail.schoolCode : ''),
    [schoolDetail],
  );

  const handleSubmitForm = async (formValue: FromValueType) => {
    const payload: ChangeFeeConfig = {
      feeName: formValue.feeName || '',
      fixedCharge: Number(formValue.fixedCharge),
      groupStudent: formValue.groupStudent,
      typeFee: formValue.typeFee,
      feePercent: Number(formValue.feePercent),
      paymentMethod: formValue.paymentMethod,
      paymentIntermediaries: formValue.paymentIntermediaries,
      amount: Number(formValue.amount),
      sign: formValue.sign,
      effectiveFromDate:
        formValue.effectiveDate && formValue.effectiveDate.length > 0
          ? dayjs(formValue.effectiveDate[0]).valueOf()
          : 0,
      effectiveToDate:
        formValue.effectiveDate && formValue.effectiveDate.length > 0
          ? dayjs(formValue.effectiveDate[1]).valueOf()
          : 0,
      status: enableEffectiveDate ? 1 : 0,
    };
    if (detailConfig) {
      payload['_id'] = detailConfig._id;
    }
    delete payload.groupStudent; // fixed now
    try {
      const res = !detailConfig
        ? await FeeConfig(orgCode, schoolCode, payload)
        : await UpdateFeeConfig(orgCode, schoolCode, payload);

      if (
        res &&
        validResponse(res) &&
        res.data.data &&
        res.data.data.success === false
      ) {
        res.data.data?.errors
          ? Sentry.captureException(res.data.data?.errors)
          : Sentry.captureMessage(res.data.data?.message);
        return (
          res &&
          validResponse(res) &&
          handleThrowError(
            res.data.data?.errors
              ? res.data.data?.errors
              : res.data.data?.message,
          )
        );
        // return message.error(`${res.data.data.message}. Vui lòng thử lại`);
      }
      if (res && validResponse(res) && res.data.success === true) {
        message.success('Thành công');
        setIsDisableForm(true);
        setSignValue(initialValue.sign);
        setEnableEffectiveDate(initialValue.effectiveDate.length === 2);
        setRefreshListConfig(true);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const onFinish = (values: FromValueType) => {
    handleSubmitForm(values);
  };

  return (
    <FormItemPannes
      onFinish={onFinish}
      initialValue={initialValue}
      isDisableForm={isDisableForm}
      setIsDisableForm={setIsDisableForm}
      signValue={signValue}
      setSignValue={setSignValue}
      enableEffectiveDate={enableEffectiveDate}
      setEnableEffectiveDate={setEnableEffectiveDate}
      paymentMethod={paymentMethod}
      paymentIntermediaries={paymentIntermediaries}
      typeFee={typeFee}
    />
  );
};

export default ItemPannes;
