export const permission: string = "ADMIN";

export enum EnumRoles {
  ADMIN = "APPROVE_UPDATE_REFUND_VNP",
  STAFF = "APPROVE_VIEW",
}

export enum EnumPermission {
  ADMIN = "ADMIN",
  OSA = "ORGANIZATION",
  CSDT = "SCHOOL",
  AUTH = "AUTH", // for auth
}

export const dateFormat = "DD/MM/YYYY";
export const TOKEN = "TOKEN";

export const ADMIN = `/${EnumPermission.ADMIN.toLocaleLowerCase()}`;
export const OSA = `/${EnumPermission.OSA.toLocaleLowerCase()}`;
export const AUTH = `/${EnumPermission.AUTH.toLocaleLowerCase()}`;
export const CSDT = `/${EnumPermission.CSDT.toLocaleLowerCase()}`;
export const DEFAULT_ROUTER = `/default`;

export const CODE_DEFAULT = -200;
export const CODE_SUCCESS = 200;
export const ERROR_NETWORK_CODE = -100;
export const RESULT_CODE_PUSH_OUT = 401;
export const TIME_OUT = 60000;
export const STATUS_TIME_OUT = "ECONNABORTED";
export const CODE_TIME_OUT = 408;
export const ERROR_CODE: {
  [index: number | string]: string;
} = {
  "400": "Bad Request",
  "401": "Unauthorized",
  "402": "Payment Required ",
  "403": "Forbidden",
  "404": "Not Found",
  "405": "Method Not Allowed",
  "406": "Not Acceptable",
  "407": "Proxy Authentication Required",
  "408": "Request Timeout",
  "409": "Conflict",
  "410": "Gone",
  "411": "Length Required",
  "412": "Precondition Failed",
  "413": "Payload Too Large",
  "414": "URI Too Long",
  "415": "Unsupported Media Type",
  "416": "Range Not Satisfiable",
  "417": "Expectation Failed",
  "500": "Internal Server Error",
  "501": "Not Implemented",
  "502": "Bad Gateway",
  "503": "Service Unavailable",
  "504": "Gateway Timeout",
  "505": "HTTP Version Not Supported",
  have_error: "An error occurred. Please try again later",
  error_on_request: "An error occurred while sending the request",
  error_on_handle: "An error occurred while processing data",
  server_error: "Server Error",
};

export const REGEX_USERNAME = new RegExp(/^[a-zA-Z0-9]+$/);
export const PATTERN_PHONE = new RegExp(/(0[2|3|5|7|8|9])+([0-9]{8,9})\b/g);
export const REGEX_NUMBER = /^[0-9\b]+$/;
export const REGEX_NUMBER_COMMA = /^[0-9]*[,]?[0-9]*$/;

// phần trăm giá trị giao dịch
export const MIN_PERCENT_TRANSACTION = 0;
export const MAX_PERCENT_TRANSACTION = 100;

export const STATUS_ACTIVE = 1;
export const STATUS_INACTIVE = 0;

export const ENABLE = "ENABLE";
export const DISABLE = "DISABLE";

export const LIST_STATUS = [
  { value: STATUS_INACTIVE, label: "In active" },
  { value: STATUS_ACTIVE, label: "Active" },
];

export enum PaymentNavigateRule {
  eq = "=",
  lt = ">",
  gt = "<",
  lte = ">=",
  gte = "=>",
}
