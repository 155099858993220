import { Box, Flex, Text } from "@chakra-ui/react";
import { Modal } from "antd";
import MainButton from "components/button";
import IconDownload from "components/icons/IconDownload";
import IconSelected from "components/icons/IconSelected";
import { HSeparator } from "components/separator/Separator";
import React from "react";
import { IReconcileCompare } from "service/OrganizationAdmin/Organization";
import { globalStyles } from "theme/styles";
import Cloes from "views/admin/OrganizationManagement/Icon/Cloes";

import UseDownloadFIleHook from "./UseDownloadFIleHook";

interface p {
  date: number;
  isOpen: boolean;
  reconciliation: IReconcileCompare;
  onClose: () => void;
}

const ModalReconciliationSuccess = ({
  date,
  isOpen,
  reconciliation,
  onClose,
}: p) => {
  const { handleDownloadExcel } = UseDownloadFIleHook({
    date,
    id: reconciliation?._id,
    fileName: reconciliation?.fileName,
  });

  return (
    <Modal
      title="Đối soát giao dịch"
      open={isOpen}
      onCancel={onClose}
      footer={<></>}
      closeIcon={
        <>
          <Cloes />
        </>
      }
    >
      <HSeparator />
      <Box px="16px" py="16px">
        <Text
          color={globalStyles.colors.text[202027]}
          fontWeight={500}
          fontSize="18px"
          textAlign={"center"}
          mb="3"
        >
          Đối soát hoàn tất
        </Text>
        <Flex justifyContent="center" flexDirection="column">
          <Box display="flex" justifyContent="center" mt="2">
            <IconSelected />
          </Box>

          <Flex my="3" justifyContent="center">
            <Text
              color={globalStyles.colors.text[202027]}
              fontWeight={500}
              fontSize="15px"
              textAlign={"center"}
            >
              Tổng giao dịch khớp:
            </Text>
            <Text
              color={globalStyles.colors.text[202027]}
              fontWeight={400}
              fontSize="15px"
              textAlign={"center"}
              pl="1"
            >
              {reconciliation?.totalCorrectTransaction} giao dịch
            </Text>
          </Flex>
          <Flex justifyContent="center">
            <Text
              color={globalStyles.colors.text[202027]}
              fontWeight={500}
              fontSize="15px"
              textAlign={"center"}
            >
              Tổng giao dịch sai lệch
            </Text>
            <Text
              color={globalStyles.colors.text[202027]}
              fontWeight={400}
              fontSize="15px"
              textAlign={"center"}
              pl="1"
            >
              {reconciliation?.totalIncorrectTransaction} giao dịch
            </Text>
          </Flex>
          <Flex justifyContent="center" my="3">
            <MainButton
              text="Tải báo cáo"
              icon={<IconDownload />}
              onClick={handleDownloadExcel}
            />
          </Flex>
        </Flex>
      </Box>
    </Modal>
  );
};

export default ModalReconciliationSuccess;
