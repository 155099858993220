// chakra imports
import { Box, Flex, Stack, Text } from "@chakra-ui/react";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
// FUNCTIONS

function SidebarContent(props: { routes: RoutesType[] }) {
  const { routes } = props;

  return (
    <Flex direction="column" height="100%" pt="25px" borderRadius="30px">
      <Brand />
      <Stack direction="column" mt="8px" mb="auto">
        <Box ps="15px" pe={"15px"}>
          <Links routes={routes} />
        </Box>
      </Stack>

      <Text p={4} pt="0">
        v300924.2
      </Text>
    </Flex>
  );
}

export default SidebarContent;
